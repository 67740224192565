import React from 'react';
import { Link } from 'gatsby';

import Button from '../Button';

import { Name, demoLink, FooterLinks } from '../../utils/constant';

import { ReactComponent as AcceleratorApp } from '../../images/icons/acceleratorApp.svg';
import styles from './Footer.module.css';

interface FooterProps {
  hideCta?: boolean;
}

function Footer({ hideCta = false }: FooterProps) {
  return (
    <div className={styles.FooterCtn}>
      <div className={styles.FooterWrapper}>
        <h1 className={styles.FooterName}>{Name}</h1>
        {!hideCta && (
          <div className={styles.FooterCTA}>
            <Button link to="/booking-demo" className={styles.GetStartedButton}>
              Get Started Now
            </Button>
            <p className={styles.CTAText}>
              Are you ready to accelerate and improve your hiring?
            </p>
          </div>
        )}
        <div className={styles.FooterInnerContainer}>
          <div className={styles.Brand}>
            <AcceleratorApp className={styles.AALogo} />
            <h2 className={styles.AcceleratorAppHeader}>
              <a
                href="https://www.acceleratorapp.co/"
                alt="Accelerator Software"
              >
                An AcceleratorApp Product
              </a>
            </h2>
            <a href="https://www.acceleratorapp.co/">Accelerate Innovation</a>
          </div>
          <div className={styles.Details}>
            <h4 className={styles.Header}>LEGAL AND DATA OWNERSHIP</h4>
            <div className={styles.LinksCtn}>
              {FooterLinks.map(link => (
                <a
                  href={link.link}
                  key={link.link}
                  className={styles.Link}
                  target="_blank"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
