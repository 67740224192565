import { ReactComponent as Organized } from '../images/icons/organized.svg';
import { ReactComponent as Impress } from '../images/icons/impress.svg';
import { ReactComponent as Easy } from '../images/icons/easy.svg';

export const Name = 'Hire Hero';

export const demoLink = 'https://calendly.com/acceleratorapp-team/hire-hero';

export const BenefitsList = [
  {
    title: 'Impress your candidates',
    description:
      'Set the standard for operational excellence from first contact.',
    image: Impress
  },
  {
    title: 'Flawless execution',
    description:
      'Process automation means no one will miss a beat and your recruiting will run like clockwork.',
    image: Organized
  },
  {
    title: 'Easy!',
    description: `Easy to get started, and easy to scale up. With ${Name}, it's quick to get up and running and expansion is seamless.`,
    image: Easy
  }
];

export const FooterLinks = [
  {
    link: 'https://www.acceleratorapp.co/legal/legal',
    name: 'Legal'
  },
  {
    link: 'https://www.acceleratorapp.co/legal/privacy',
    name: 'Privacy'
  },
  {
    link: 'https://www.acceleratorapp.co/legal/cookies',
    name: 'Cookies'
  },
  {
    link: 'https://www.acceleratorapp.co/legal/terms',
    name: 'Terms of Use'
  },
  {
    link: 'https://www.acceleratorapp.co/legal/dataProcessingAddendum',
    name: 'DPA'
  },
  {
    link: 'https://www.acceleratorapp.co/legal/subProcessors',
    name: 'Subprocessors'
  }
];

export const Plans = [
  {
    name: 'Start',
    subHeader: 'The cost saver',
    includedItems: [
      'Up to 3 job postings at once',
      'Calendar integration for interview scheduling',
      'Application & evaluation form builder',
      'Your own interview flow of up to 3 rounds',
      'Include up to 3 people to review applicants per job posting',
      'Table dashboard'
    ],
    notIncludedItems: [
      'No logic jumps',
      'No video questions',
      'No visual dashboard'
    ],
    priceUSD: 89,
    priceEUR: 69
  },
  {
    name: 'Grow',
    subHeader: 'Scaling up',
    RecommendedPlan: true,
    includedItems: [
      'Up to 8 job postings at once',
      'Calendar integration for interview scheduling',
      'Application & evaluation form builder',
      'Your own interview flow of up to 10 rounds',
      'Include up to 5 people to review applicants per job posting',
      'Table dashboard',
      'Logic jumps',
      'Video questions'
    ],
    notIncludedItems: ['No visual dashboard'],
    priceUSD: 149,
    priceEUR: 139
  },
  {
    name: 'Scale',
    subHeader: 'Large secure operations',
    includedItems: [
      'Unlimited job postings',
      'Calendar integration for interview scheduling',
      'Application & evaluation form builder',
      'Your own interview flow of up to 10 rounds',
      'Include as many people to review applicants per job posting as you want.',
      'Table dashboard',
      'Logic jumps',
      'Video questions',
      'Visual dashboard'
    ],
    notIncludedItems: [],
    priceUSD: null,
    priceEUR: null
  }
];
