import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import ClassNames from 'classnames';

import Button from '../Button';

import { Name, demoLink } from '../../utils/constant';

import styles from './Header.module.css';

interface HeaderProps {
  hideCta?: boolean;
}

function Header({ hideCta = false }: HeaderProps) {
  const [iScrolled, setIScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -100) setIScrolled(true);
      if (currPos.y > -100) setIScrolled(false);
    },
    [iScrolled]
  );

  return (
    <div
      className={ClassNames(styles.HeaderCtn, {
        [styles.IsScrolled]: iScrolled
      })}
    >
      <div className={styles.Wrapper}>
        <div className={styles.LogoCtn}>
          <h1>{Name}</h1>
        </div>
        {!hideCta && (
          <div className={styles.CTACtn}>
            <Button
              link
              to="/booking-demo"
              aria-label="Book a demo with Hire Hero"
            >
              Get Started
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
