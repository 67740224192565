import React from 'react';
import { Link, GatsbyLinkProps } from 'gatsby';
import classNames from 'classnames';

import styles from './Button.module.css';

interface BaseButtonProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  href?: string;
  link?: boolean;
  to?: string | null;
  notButtonStyle?: boolean;
}

export type ButtonProps =
  | (BaseButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>)
  | (BaseButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | (BaseButtonProps & GatsbyLinkProps<Link>);

function Button(
  {
    className,
    children,
    href,
    to = null,
    link = false,
    notButtonStyle = false,
    ...rest
  }: ButtonProps,
  ref
) {
  const classes = classNames(className, styles.BaseButton, {
    [styles.Button]: !notButtonStyle
  });

  if (href) {
    return (
      <a className={classes} href={href} {...rest}>
        {children}
      </a>
    );
  }

  if (link && to) {
    return (
      <Link
        className={classes}
        to={to}
        ref={ref as React.RefObject<HTMLButtonElement>}
        {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </Link>
    );
  }

  if (link && !to) {
    return (
      <div
        className={classes}
        role="link"
        ref={ref as React.RefObject<HTMLButtonElement>}
        {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </div>
    );
  }

  return (
    <button
      type="button"
      ref={ref as React.RefObject<HTMLButtonElement>}
      className={classes}
      {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
}

export default React.forwardRef(Button);
